import autoBind from 'auto-bind';

class RestaurantsContainer extends HTMLElement {
  constructor() {
    super();
    autoBind(this);
  }

  render() {
    this.innerHTML = `
      <section class="cards" id="cards-container">
        <loading-indicator></loading-indicator>
      </section>
    `;
  }

  connectedCallback() {
    this.render();
  }

  get restaurants() {
    return this._restaurants;
  }

  set restaurants(values) {
    this._restaurants = values;
    this.renderRestaurants();
  }

  renderRestaurants() {
    const cardsContainer = this.querySelector('#cards-container');
    cardsContainer.innerHTML = '';

    // const restaurantCards = this._restaurants.map((restaurant) => {
    //   const restaurantCard = document.createElement('restaurant-card');
    //   restaurantCard.classList.add('card');
    //   restaurantCard.classList.add('show');
    //   restaurantCard.restaurant = restaurant;
    //   return restaurantCard;
    // });

    // cardsContainer.append(...restaurantCards);

    this._restaurants.forEach((restaurant) => {
      const restaurantCard = document.createElement('restaurant-card');
      restaurantCard.classList.add('card');
      restaurantCard.classList.add('show');
      restaurantCard.restaurant = restaurant;
      cardsContainer.appendChild(restaurantCard);
    });
  }
}

customElements.define('restaurants-container', RestaurantsContainer);
