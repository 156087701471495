import { generateStars } from '../../utils/restaurant-utils';

class RestaurantDetail extends HTMLElement {
  constructor() {
    super();

    this.innerHTML = '<loading-indicator></loading-indicator>';
  }

  get restaurant() {
    return this._restaurant;
  }

  set restaurant(value) {
    if (typeof value === 'object') {
      this._restaurant = value;
      this.render();
    }
  }

  render() {
    const r = this._restaurant;
    this.innerHTML = `
        <section class="restaurant-info">
          <div>
            <img class="restaurant-image" src="https://restaurant-api.dicoding.dev/images/small/${r.pictureId}" alt="${r.name}">
          </div>
          <div>
            <h1>${r.name}</h1>
            <div class="rating" title="${r.rating} stars">
              ${generateStars(r.rating)}
            </div>
            <div class="location" aria-label="Location: ${r.address}, ${r.city}">
              <i class="fa fa-map-marker-alt" aria-hidden="true"></i>
              <span>${r.address}, ${r.city}</span>
            </div>
            <div class="category" aria-label="Categories: ${r.categories.map((e) => e.name).join(', ')}">
              <i class="fa fa-folder" aria-hidden="true"></i>
              <span>${r.categories.map((e) => e.name).join(', ')}</span>
            </div>
            <favorite-button class="favorite"></favorite-button>
          </div>
        </section>
      
        <section class="restaurant-description">
          <h2>About This Restaurant</h2>
          <p>${r.description}</p>
        </section>

        <section class="restaurant-menu">
          <h2>Menu</h2>
          
          <h3>Food</h3>
          <ul>${r.menus.foods.map((e) => `<li>${e.name}</li>`).join('')}</ul>

          <h3>Drinks</h3>
          <ul>${r.menus.drinks.map((e) => `<li>${e.name}</li>`).join('')}</ul>
        </section>
      
        <section class="reviews">
          <h2>Customer Reviews</h2>
          <review-container></review-container>
        </section>
    `;

    const favButton = this.querySelector('favorite-button');
    const reviewContainer = this.querySelector('review-container');

    reviewContainer.restaurant = this._restaurant;
    favButton.restaurant = this._restaurant;
  }
}

customElements.define('restaurant-detail', RestaurantDetail);
