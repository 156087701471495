class LoadingIndicator extends HTMLElement {
  constructor() {
    super();
  }

  render() {
    this.innerHTML = `
      <div class="loading">
        <div class="spinner"></div>
        Loading...
      </div>
    `;
  }

  connectedCallback() {
    this.render();
  }
}

customElements.define('loading-indicator', LoadingIndicator);
