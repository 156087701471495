class ReviewItem extends HTMLElement {
  constructor() {
    super();
  }

  get review() {
    return this._review;
  }

  set review(value) {
    if (typeof value === 'object') {
      this._review = value;
      this.render();
    }
  }

  render() {
    const r = this._review;
    this.innerHTML = `
    <div class="review">
      <div class="avatar">${r.name[0]}</div>
      <div class="content">
        <h4>${r.name}</h4>
        <div class="date">${r.date}</div>
        <p>${r.review}</p>
      </div>
    </div>
    `;
  }
}

customElements.define('review-item', ReviewItem);
