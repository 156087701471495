import autoBind from 'auto-bind';
import RestaurantApi from '../../data/restaurant-api';
import Swal from 'sweetalert2';

class ReviewContainer extends HTMLElement {
  constructor() {
    super();
    autoBind(this);
  }

  get restaurant() {
    return this._restaurant;
  }

  set restaurant(value) {
    if (typeof value === 'object') {
      this._restaurant = value;
      this._reviews = value.customerReviews || [];
      this.render();
    }
  }

  addReview(review) {
    this._reviews = this._reviews || [];
    this._reviews.push(review);
    this.render();
  }

  render() {
    this.innerHTML = `
      <section class="reviews">
        ${this._reviews.map((r, i) => `<review-item id="review-${i}"></review-item>`).join('')}
      </section>
      
      <h3>Post a Review</h3>
      <form action="" class="review-form">
        <input type="text" id="review-name" name="review-name" placeholder="Name..." required aria-label="Name">
        <textarea id="review-text" name="review-text" placeholder="Write a review..." required aria-label="Review text"></textarea>
        <button type="submit" id="review-submit-button">Submit</button>
      </form>

    `;

    this._reviews.forEach((r, i) => {
      const reviewElem = this.querySelector(`#review-${i}`);
      reviewElem.review = r;
    });

    const reviewForm = this.querySelector('.review-form');
    reviewForm.onsubmit = this.postReview;
  }

  async postReview(e) {
    e.preventDefault();

    if (!this._restaurant) return;

    const nameInput = this.querySelector('#review-name');
    const reviewInput = this.querySelector('#review-text');
    const submitBtn = this.querySelector('#review-submit-button');

    nameInput.disabled = true;
    reviewInput.disabled = true;
    submitBtn.disabled = true;
    submitBtn.innerHTML = 'Posting review...';

    const name = nameInput.value;
    const review = reviewInput.value;

    try {
      const res = await RestaurantApi.addReview(
        this._restaurant.id,
        name,
        review
      );

      this._reviews = res;
      this.render();

      await Swal.fire({
        title: 'Success!',
        text: 'Review has been posted',
        icon: 'success',
      });
    } catch (error) {
      console.log(error);

      await Swal.fire({
        title: 'Error!',
        text: 'Failed to post the review',
        icon: 'error',
      });

      nameInput.disabled = false;
      reviewInput.disabled = false;
      submitBtn.disabled = false;
      submitBtn.innerHTML = 'Submit';
      nameInput.focus();
    }
  }
}

customElements.define('review-container', ReviewContainer);
