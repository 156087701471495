import { generateStars, generateExcerpt } from '../../utils/restaurant-utils';

class RestaurantCard extends HTMLElement {
  constructor() {
    super();
  }

  get restaurant() {
    return this._restaurant;
  }

  set restaurant(value) {
    if (typeof value === 'object') {
      this._restaurant = value;
      this.render();
    }
  }

  render() {
    const r = this._restaurant;
    this.innerHTML = `
      <div class="location">${r.city}</div>
      <img class="lazyload" src="/images/placeholder.png" data-src="https://restaurant-api.dicoding.dev/images/small/${r.pictureId}" alt="${r.name}">
      <div class="rating" title="${this._rating} Stars">
        ${generateStars(r.rating)}
      </div>
      <h3>
        <a href="#/detail/${r.id}">
          ${r.name}
        </a>
      </h3>
      <p>${generateExcerpt(r.description)}</p>
    `;
  }
}

customElements.define('restaurant-card', RestaurantCard);
